#education {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    padding-bottom: 10px;
    scroll-margin-top: 125px;
}

.individualEducation {
    width: 400px;
    text-align: center;
}

.logo {
    height: 150px;
    width: auto;
}

.educationTitle {
    color: #c27968;
    font-family: 'Oxygen', sans-serif;
}

.date {
    color: #9c775c;
    font-family: 'Oxygen', sans-serif;
    font-weight: 300;
    font-style: italic;
}

#education a {
    color: blue;
}

@media only screen and (max-width: 768px) {
    #education {
        gap: 30px;
    }
}