#navbar {
    top: 0;
    right: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.hamburger {
    position: relative;
    display: block;
    width: 35px;
    cursor: pointer;
    appearance: none;
    background: none;
    outline: none;
    background: none;
    border: none;
}

.hamburger .bar, .hamburger:after, .hamburger:before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: black;
    margin: 6px 0px;
    transition: 0.4s ease-in-out;
}

.hamburger.isActive:before {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger.isActive .bar {
    opacity: 0;
}

.hamburger.isActive:after {
    transform: rotate(45deg) translate(-5px, -6px);
}

.internalLinks {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 0;
    background-color: #54727f;
    height: 100%;
    gap: 40px;
    padding: 20px;
    transition: 0.2s ease-in-out;
}

.internalLinks > * {
    text-align: center;
    width: 100%;
}

.internalLinks.isActive {
    visibility: visible;
    width: 120px;
}