* {
    box-sizing: border-box;
    font-family: Georgia, 'Times New Roman', Times, serif;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
}

#bg {
    position: absolute;
    background-color: #EDEDED;
}

.sectionHeader {
    font-family: 'Oxygen', sans-serif;
    color: #9b6c74;
    position: relative;
    width: 80%;
    margin: 3% 0 3% 10% ;
    font-size: 40px;
}

.sectionHeader:before, .sectionHeader:after {
    content: "";
    display: block;
    border: 0.1px solid black;
}

a {
    text-decoration: none;
    color: white;
}

a:hover {
    text-decoration: underline;
}

h2 {
    text-align: center;
}