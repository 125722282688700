#landing {
    scroll-margin-top: 75px;
    margin: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
}

#headshot {
    width: 250px;
    border-radius: 50%;
}

h1 {
    color: #54727f;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-weight: 800;
    font-size: 50px;
}

@media only screen and (max-width: 768px) {
    #landing {
        flex-direction: column;
        gap: 25px;
    }
}