#skills {
    color: #6c7b82;
    font-family: Georgia, 'Times New Roman', Times, serif;
    scroll-margin-top: 125px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 40px;
    justify-content: center;
    padding: 0 5%;
}

#skills > * {
    transition: transform 0.2s;
}

#skills > *:hover {
    transform: scale(1.1);
} 