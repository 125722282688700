#footer {
    left: 0;
    bottom: 0;
    position: fixed;
    width: 100vw;
    padding: 20px 20px 0px;
    background-color: #54727f;
    color: white;
    text-align: center;
}

#contact {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;
}

.contactLogo {
    width: 30px;
    height: 30px;
    transition: transform 0.2s, rotate 0.2s;
    transition-timing-function: ease-in-out;
}

.contactLogo:hover {
    transform: scale(1.1);
    rotate: -8deg;
}

@media only screen and (max-width: 768px) {
    .contactLogo {
        width: 20px;
        height: 20px;
    }
}