#projects {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-bottom: 133px;
    scroll-margin-top: 125px;
}

.individualProject {
    width: 45%;
    padding: 15px;
}

.projectRole {
    color: #9b6c74;
    font-family: 'Oxygen', sans-serif;
    text-align: left;
}

.projectSpecifics {
    color: #9c775c;
    font-family: 'Oxygen', sans-serif;
    font-style: italic;
}

.duration {
    text-align: left;
}

.projectName {
    color: #c27968;
}

.results {
    color: black;
    font-size: 1em;
    font-weight: 400;
    font-style: italic;
}

.pdf {
    color: black;
    text-decoration: underline;
}

.pdf:hover {
    color: blue;
}

ul {
    list-style-position: outside;
    padding-left: 1.5em;
}

.objective {
    padding-top: 10px;
}

.individualProject > * {
    text-align: left;
}

@media only screen and (max-width: 768px) {
    #projects {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .individualProject {
        width: 80vw;
    }
}